<template>
  <div>
    <v-app-bar
    dark
    color="white"
    elevation="1"
    scroll-off-screen
    >
      <v-container :fluid="$vuetify.breakpoint.mdAndDown">
        <v-row align="center" justify="space-between">
            <a exact href="https://www.overseasstudent.com">
              <img src="../static/ossc_logo-02.png" class="" alt="adam logo" width="200">
            </a>

            <v-spacer></v-spacer>

            <div class="d-none d-md-flex">
              <router-link
              v-for="tab in tabs"
              :key="tab.title"
              exact
              :to="{ name: tab.route}"
              :class="$route.name !== tab.title ? 'greyText--text' : 'blueDark--text'"
              >
                <span class="body-1 mx-3 text-uppercase" :class="$route.name !== tab.title ? 'font-weight-medium' : 'font-weight-black'">{{tab.title}}</span>
              </router-link>
            </div>

            <div class="d-none d-md-flex">
              <a exact href="https://www.overseasstudent.com/about-ossc/" target="_blank">
                <span class="body-1 mx-3 text-uppercase font-weight-medium greyText--text">about us</span>
              </a>
            </div>

            <v-btn icon @click="drawer = !drawer"
            class="d-flex d-md-none"
            >
              <v-icon large color="secondary">mdi-menu</v-icon>
            </v-btn>

            <!-- <v-spacer></v-spacer>

            <router-link class="text-decoration-none d-none d-sm-flex" :to="{ name: 'login'} " v-if="!$cookies.isKey('userToken')">
              <v-btn
              elevation="0"
              :large="$vuetify.breakpoint.lgAndUp"
              :small="$vuetify.breakpoint.mdAndDown"
              color="secondary"
              height="47"
              class="px-10 ml-1"
              >
                <span>Login</span>
                <v-icon right>mdi-login-variant</v-icon>
              </v-btn>
            </router-link> -->

            <!-- <v-menu offset-y v-else class="d-none d-sm-flex">
              <template v-slot:activator="{ on }">
                <v-btn
                color="white --text"
                text
                v-on="on"
                >
                  <v-avatar color="secondary" class="mr-2" size="40">
                    <span class="white--text body-1">
                      {{user.name.charAt(0).toUpperCase()}}
                    </span>
                  </v-avatar>
                  <span class="d-none d-sm-flex secondary--text">{{user.name.substr(0, user.name.indexOf(' '))}}</span>
                </v-btn>
              </template>
              <v-list>
                <v-list-item link exact :to="{ name: 'profile' }">
                  <v-list-item-icon><v-icon>mdi-account</v-icon></v-list-item-icon>
                  <v-list-item-title>Profile</v-list-item-title>
                </v-list-item>
                <v-list-item @click="logout">
                  <v-list-item-icon><v-icon>mdi-logout</v-icon></v-list-item-icon>
                  <v-list-item-title>Logout</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu> -->
        </v-row>
      </v-container>

    </v-app-bar>

    <v-navigation-drawer
    v-model="drawer"
    absolute
    color="white"
    dark
    temporary
    >
      <v-list
      nav
      dense
      >
        <v-list-item-group
        v-model="group"
        active-class="secondary--text text-accent-4"
        >
          <v-list-item>
            <a exact href="https://www.overseasstudent.com">
              <img src="../static/ossc_logo-02.png" class="" alt="adam logo" width="200">
            </a>
          </v-list-item>

          <!-- <v-list-item two-line link exact :to="{ name: 'login' }" v-if="!$cookies.isKey('userToken')">
            <v-list-item-avatar>
              <v-icon color="secondary">mdi-account-circle</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title class="secondary--text">Login / Register</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item two-line link exact :to="{ name: 'profile' }" v-else>
            <v-list-item-avatar v-if="user !== null">
              <v-avatar color="secondary" class="text-center" size="32">

                  <span class="white--text body-1">
                    {{user.name.charAt(0).toUpperCase()}}
                  </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content>

              <v-list-item-title v-cloak class="secondary--text">{{user.name}}</v-list-item-title>
            </v-list-item-content>
          </v-list-item> -->

          <v-list-item class="mt-5 text-uppercase" active-class="blueDark" link exact :to="{ name: item.route }" v-for="item in tabs" :key="item.title">
              <v-list-item-title class="font-weight-black text-center" :class="$route.name === item.title ? 'white--text' : 'greyText--text'">
                {{item.title}}
              </v-list-item-title>
          </v-list-item>

          <v-list-item class="mt-5">
            <a exact href="https://www.overseasstudent.com/about-ossc/" target="_blank" class="d-block mx-auto">
              <span class="body-1 mx-3 text-uppercase font-weight-medium greyText--text">about us</span>
            </a>
          </v-list-item>

          <!-- <v-list-item>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                class="py-2"
                color="blueDark"
                dark
                v-bind="attrs"
                outlined
                large
                v-on="on"
                >
                  <v-icon left>mdi-cash</v-icon> {{currency.code}}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                v-for="(item, i) in currencies"
                :key="i"
                @click="$store.dispatch('setCurrency', item)"
                >
                  <v-list-item-title>{{ item.code }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-list-item> -->
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { logout, headers } from '../links'
import { mapState } from 'vuex'

export default {
  data () {
    return {
      drawer: false,
      group: null,
      tabs: [
        { title: 'flights', route: 'flights', icon: 'mdi-airplane' }
        // { title: 'hotels', route: 'hotels', icon: 'mdi-domain' }
        // { title: 'cars', route: 'cars', icon: 'mdi-car' }
      ],
      // links: [
      //   { title: 'home', route: 'Home' },
      //   { title: 'trips', route: 'trips' },
      //   { title: 'about us', route: 'about' },
      //   { title: 'rewards', route: 'rewards' }
      // ],
      user: null
    }
  },
  computed: {
    userStore () {
      return this.$store.state.user
    },
    ...mapState(['currency', 'currencies'])
  },
  watch: {
    userStore (newVal) {
      this.user = newVal
    },
    group () {
      this.drawer = false
    }
  },
  methods: {
    logout () {
      this.$http.post(logout, null, { headers: headers(this.$cookies.get('userToken')) }).then(() => {
        this.$store.dispatch('removeUser')
        this.$cookies.remove('userToken')
        localStorage.removeItem('userId')
        localStorage.removeItem('token')
        this.$router.push({ name: 'login' })
      })
    }
  },
  created () {
    this.user = this.$store.state.user
  }
}
</script>
