import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#333',
        secondary: '#150958',
        greyText: '#707070',
        orange: '#F58D33',
        blueDark: '#2E3191',
        blueLight: '#4D608D'
      }
    }
  }
})
