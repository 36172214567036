<template>
  <div>
    <v-footer
    padless
    elevation="3"
    >
      <v-card
        tile
        width="100%"
        color="#222"
      >
        <v-card-text>
          <v-container fluid>
            <v-row justify="space-between">
              <v-col cols="12" sm="6" md="4" lg="3">
                <p class="headline blueDark--text">Copyright</p>
                <p class="body-1 mt-5 white--text">
                  overseasstudent.com © Copyright from 2012. All Right Reserved. overseasstudent.com is not responsible for any errors, omissions and change in price, prior sale, rent and withdrawal without notice. All information from sources reliable but not guaranteed.
                </p>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <p class="headline blueDark--text">Global International Students Network</p>
                <ul>
                  <li v-for="link in networkLinks" :key="link.url" class="blueDark--text mb-3">
                    <a :href="link.url" target="_blank" class="white--text" rel="noopener noreferrer">{{ link.label }}</a>
                  </li>
                </ul>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <p class="headline blueDark--text">Global International Students Network</p>
                <ul>
                  <li v-for="link in subsidiariesLinks" :key="link.url" class="blueDark--text mb-3">
                    <a :href="link.url" target="_blank" class="white--text" rel="noopener noreferrer">{{ link.label }}</a>
                  </li>
                </ul>
              </v-col>
              <v-col cols="12" sm="6" md="4" lg="3">
                <p class="headline blueDark--text">Contact Us</p>
                <p class="white--text">
                  contact us
                  Boston Headquarters:
                  99 Sumner St, East Boston, MA 02111
                  <br>
                  <br>
                  Boston Allston Office:
                  1110 Commonwealth Ave, Boston, MA
                  <br>
                  <br>
                  New York Branch:
                  5 West 37th St, Fl 2, New York, NY
                  <br>
                  <br>
                  London Branch, UK:
                  36A, PARK ROAD, LONDON, NW4 3PS
                  <br>
                  <br>
                  Changchun Branch:
                  Room 2012, Floor 20, Building C, No. 1567 Tianfu Road, Nanguan District, Changchun City
                  <br>
                  <br>
                  Shenzhen Branch:
                  Room 440, Building D, Hengyu Shenzhen Bay, Chuangye Road, Nanshan District, Shenzhen
                </p>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-footer>
  </div>
</template>

<script>
export default {
  data () {
    return {
      networkLinks: [
        { label: 'OSSC International Student Network', url: 'https://www.overseasstudent.com/' },
        { label: 'Boston International Students Network', url: 'https://www.bostonstudents.org/' },
        { label: 'New York International Students Network', url: 'https://www.nystudents.net/' },
        { label: 'California International Students Network', url: 'http://www.castudents.org/' },
        { label: 'UK International Students Network', url: 'http://www.ukstudents.net/' },
        { label: 'North American International Student Foundation', url: 'https://www.overseasstudentsfoundation.org/' }
      ],
      subsidiariesLinks: [
        { label: 'LUMINARY Study abroad', url: 'https://www.phemiaedu.com/' },
        { label: 'LUMINARY Stay in Ming·Job Search', url: 'https://www.mingtucareer.com/' },
        { label: 'USWOO Meiwo Real Estate', url: 'https://www.uswoo.com/' },
        { label: 'OSSCINS Tumei Insurance', url: 'https://www.osscinsurance.com/' },
        { label: 'TSI Dental Clinic', url: 'https://thesmileinstitute.com/' },
        { label: 'WUKONG Wukong Media', url: 'https://www.wukongmedia.us/' }
      ]
    }
  }
}
</script>

<style scoped>
</style>
